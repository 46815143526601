'use client'

import {React} from 'react';
import { useTypewriter} from 'react-simple-typewriter';

const TypingEffect = ({word}) => {

  const [text] = useTypewriter({
    words: [word],
    typeSpeed: 45,
    deleteSpeed: 0,
  });

  return (
    <div>
      <h1 style={{ fontWeight: 'bold', color: 'white', textAlign: "center"}}> {text}</h1>
    </div>
  );
};

export default TypingEffect;
