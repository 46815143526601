'use client';

import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import './input.css';

// Vector Line Component
const VectorLine = ({ start, end, color }) => {
  const lineRef = useRef();

  useEffect(() => {
    if (lineRef.current) {
      const geometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(...start),
        new THREE.Vector3(...end)
      ]);
      lineRef.current.geometry = geometry;
    }
  }, [start, end]);

  return (
    <line ref={lineRef}>
      <bufferGeometry />
      <lineBasicMaterial color={color} linewidth={2} />
    </line>
  );
};

// Axes Lines Component
const AxesLines = () => {
  const axes = [
    { start: [0, 0, 0], end: [10, 0, 0], color: 'red' }, // X axis
    { start: [0, 0, 0], end: [0, 10, 0], color: 'red' }, // Y axis
    { start: [0, 0, 0], end: [0, 0, 10], color: 'red' }  // Z axis
  ];

  return axes.map((axis, index) => (
    <VectorLine key={index} start={axis.start} end={axis.end} color={axis.color} />
  ));
};

// Input Form Component
const VectorInputForm = ({ setVector }) => {
  const [x, setX] = useState(1);
  const [y, setY] = useState(1);
  const [z, setZ] = useState(1);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newVector = [x, y, z];
    console.log("New Vector:", newVector); // Log the new vector to confirm the update
    setVector(newVector);
  };

  return (
    <form onSubmit={handleSubmit} style={{ position: 'absolute', top: 10, color: 'black', left: 10, zIndex: 1, background: 'white', padding: '10px', borderRadius: '5px' }}>
      <div>
        <label>
          X: 
          <input type="number" value={x} onChange={(e) => setX(Number(e.target.value))} />
        </label>
      </div>
      <div>
        <label>
          Y: 
          <input type="number" value={y} onChange={(e) => setY(Number(e.target.value))} />
        </label>
      </div>
      <div>
        <label>
          Z: 
          <input type="number" value={z} onChange={(e) => setZ(Number(e.target.value))} />
        </label>
      </div>
      <button type="submit" style={{color: "black"}}>Update Vector</button>
    </form>
  );
};

// Main Component
const Spinning3DObject = () => {
  const [vector, setVector] = useState([1, 1, 1]);

  console.log("Current Vector:", vector); // Log the current vector to confirm re-render

  return (
    <div style={{ display: 'flex' }}>
      <VectorInputForm setVector={setVector} />
      <div style={{ width: '400px', height: '400px' }}>
        <Canvas style={{ width: '100%', height: '100%' }}>
          <ambientLight intensity={0.2} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />
          <SpinningBox />
          <AxesLines />
          <VectorLine start={[0, 0, 0]} end={vector} color="green" />
          <OrbitControls />
        </Canvas>
      </div>
    </div>
  );
};

// Spinning Box Component
const SpinningBox = () => {
  const meshRef = useRef();

  

  return (
    <mesh ref={meshRef}>
      <boxGeometry args={[0.1, 0.1, 0.1]} />
      <meshStandardMaterial color="pink" />
    </mesh>
  );
};

export default Spinning3DObject;
