import React from 'react'
import './styles/ImageGrid.css'

const Goats = () => {
  const lauryn = 'https://i1.sndcdn.com/artworks-000062839602-9u8ys7-t500x500.jpg';
  const woz = 'https://www.speakersassociates.com/wp-content/uploads/2021/12/steve-wozniak-speaker-e1623812363247.jpg';
  const jaylen = 'https://preview.redd.it/jaylen-brown-is-an-nba-champion-v0-whhm9nbyx87d1.jpeg?auto=webp&s=10702729b1a641a1b16e17de441cf486d636def7';
  const chris = 'https://www.sarniahistoricalsociety.com/wp-content/uploads/2015/06/Chris-Hadfield-Sarnias-Man-in-Space.jpg';
    const images = [
      { src: 'https://upload.wikimedia.org/wikipedia/commons/9/9b/Carl_Friedrich_Gauss.jpg', alt: 'Gauss', text: 'Carl Friedrich Gauss' },
      { src: 'https://static01.nyt.com/images/2023/11/16/arts/14andre-item/14andre-item-mediumSquareAt3X.png', alt: 'Andre 3k', text: 'Andre 3k' },
      { src: 'https://people.com/thmb/zj1h1umGpUCeWDdJbzgw5K1UZSs=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(979x539:981x541)/dennis-rodman-1-5cd0f61b2ac34f7793682eabc837877e.jpg', alt: 'Dennis Rodman', text: 'Dennis Rodman' },
      { src: 'https://upload.wikimedia.org/wikipedia/commons/d/d8/Bolyai_J%C3%A1nos_%28M%C3%A1rkos_Ferenc_festm%C3%A9nye%29.jpg', alt: 'János Bolyai', text: 'János Bolyai' },
      { src: lauryn, alt: 'János Bolyai', text: 'Lauryn Hill' },
      { src: woz, alt: 'Steve Wozniak', text: 'Berkeley, San Jose, and he\'s an amazing guy' },
      { src: jaylen, alt: 'Jaylen Brown', text: 'UC Berkeley Chess Captain, and 2024 NBA Champ' },
      { src: chris, alt: 'Chris Hadfield', text: 'My hero as a kid'}

    ];
    return (
        <div className="grid-container">
          {images.map((image, index) => (
            <div key={index} className="grid-item">
              <img src={image.src} alt={image.alt} className="image" />
              <div className="overlay">
                <div className="text">{image.text}</div>
              </div>
            </div>
          ))}
        </div>
    );
};

export default Goats;
