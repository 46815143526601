'use client'

import React, { Component } from "react";
import "./styles/global.css";
import TypingEffect from "./typingeffect";
import Goats from "./goats";
import SimpleSVG from "./VectorPage";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import GroqPage from "./GroqPage";
import SynonymNetwork from "./TestPage.tsx";
import AirbnbMov from './airbnb';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "about"
    };
  }

  render() {
    return (
      <Router>
        <Link className="header" to="/">
          <div className="flex justify-center items-center" style={{ height: '40px', marginTop: '15px' }}>
            <TypingEffect word="sam schnaars" />
          </div>
        </Link>
        <div className="flex flex-col justify-center items-center min-h-screen">
          <Routes>
            <Route path="/goats" element={<Goats />} />
            <Route path="/vectors" element={<SimpleSVG />} />
            <Route path="/groq" element={<GroqPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/airbnb" element={<AirbnbMov/>} />
          </Routes>
        </div>
      </Router>
    );
  }
}

class HomePage extends Component {
  render() {
    return (
      <html lang="en">
        <head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link href="./output.css" rel="stylesheet" />
          <link rel="icon" href="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Latin_capital_letter_S_with_straight_stroke.svg/963px-Latin_capital_letter_S_with_straight_stroke.svg.png" />
        </head>
        <meta charSet="UTF-8" title="samarei.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Helvetica:wght@400;700&display=swap" />
        <link href="./output.css" rel="stylesheet" />
        <div className="flex flex-col justify-center items-center min-h-screen">
          <div className="container">
            <Link className="link" to="/goats">goats</Link>
            <Link className="link" to="/vectors">vectors</Link>
            <Link className="link" to="/groq">synonym ai</Link>
            <Link className="link" to="/airbnb">airbnb</Link>
          </div>
        </div>
      </html>
    );
  }
}

export default App;