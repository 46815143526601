import React from 'react'
import './styles/ImageGrid.css'

const AirbnbMov = () => {
    return (
      <div className="flex mx-auto w-[60%] p-6 text-center">
      <h1 className="text-3xl font-extrabold text-center text-white mb-8">
          (In process) Using <a className="underline" href="https://insideairbnb.com/get-the-data/" target="_blank" rel="noopener noreferrer">
        public data
      </a> and property records to find the largest gaps between single day rentals and property prices...
        </h1>
        <img 
        src="https://i.ibb.co/1qMg173/Screenshot-2024-09-19-at-4-28-02-PM.png" 
        alt="Descriptive Alt Text" 
        className="w-[60%] h-auto rounded-lg shadow-lg" />
        </div>
    );
};

export default AirbnbMov;
