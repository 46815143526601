'use client';

import React, { useState} from 'react';
import Groq from 'groq-sdk';
import TypingEffect from './typingeffect';
import styles from "./styles/groq.module.css";
import "./input.css";
import { Center } from '@react-three/drei';

const groq = new Groq({
  apiKey: process.env.REACT_APP_GROQ_API_KEY,
  dangerouslyAllowBrowser: true,
});


export async function getGroqChatCompletion(question) {
  return groq.chat.completions.create({
    messages: [
      {
        role: 'user',
        content: question,
      },
    ],
    model: 'llama3-8b-8192',
  });
}

export default function GroqPage() {
  const [data, setData] = useState('');
  const [question, setQuestion] = useState('');
  const [arrayData, setArray] = useState([]);
  const [introVisible, setIntroVisible] = useState(true);
  const promptArray = [`Give me exactly ten single word synonyms of ${question}. Return no other text, such as any kind of introduction, separate each synonym with a comma`, `Give me three artistic phrases of ${question}, separate each with a comma, return no other text`]

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(question);
    if (!question.trim()) {
      setData('');
      setArray([]);
      return;
    }
    try {
      setQuestion(e.target.value);
      let arrayData = [];
      let content = '';
      console.log(promptArray);
      for (const prompt of promptArray ) {
        const content = await getGroqChatCompletion(prompt);
        arrayData.push(textToArray(content.choices[0]?.message?.content || ''))
      }
      setData(content);
      setArray(arrayData);
      console.log(arrayData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  

  return (
    <div className='bg-white mx-auto text-center'>
    <h1 className="text-3xl font-extrabold text-center text-gray-900 mb-8">
      Synonym Network Generator
    </h1>
    <div className={styles.synonymApp}>
        <form className={styles.form} onSubmit={handleSubmit}>
            <text style={{margin: "5px"}}>Origin Word:  </text>
        <label>
            <input
            type="text"
            value={question}
            style={{textAlign: "center", border: "2px solid rgb(0, 0, 0)", borderRadius: "10px"}}
            onChange={(e) => (setQuestion(e.target.value))}/>
        </label>
        <button type="submit" style={{margin: "5px", border: "2px solid black", borderRadius: "5px", padding: "2px"}}>Ask</button>
        
        </form>
        <div>
            {(arrayData).map((item, index) => (
              <div className={styles.buttonDiv} key={index}>
                {item.map((item, index) => <form onSubmit={handleSubmit}><button key={index} className={styles.synonymButton} 
                  type = "submit" onClick={() => setQuestion(item)}>{item}
                </button></form>)}
              </div>
            ))}
        </div>
    </div>
    </div>
  );
}

const textToArray = (text) => {
  console.log(text);
  return text.replace(/['"]/g, '').split(',').map((item) => item.trim());
};

function shuffle(array) {
  let currentIndex = array.length;
  while (currentIndex !== 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array
}
